import React from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";
import styled from "styled-components";
import config from "../../../config";

const ServicesHoriz = ({ nodes }) => {

  return (

    <Container>

    	<ServicesContainer>
          {
            nodes.map(service => (
              <ServiceLink to={service.node.path.alias}>
                <div className="service-image">
                  <Img fluid={service.node.relationships.field_banner_image.localFile.childImageSharp.fluid} alt={service.node.title} />
                </div>  
                <div className="services-content">
                 <h3>{service.node.title}</h3>
                 <p>{service.node.field_summary}</p>
                <span className="btn">Find out more</span>
                </div>
              </ServiceLink>  
            ))
          }

    	</ServicesContainer>
	   
    </Container>  
  );
}

const ServiceLink = styled(Link) `
	text-decoration: none;
	color: ${config.secondaryColor};
  display: flex;
  flex-direction: row;
  margin: 30px 0;

  @media(max-width: 600px) {
    flex-direction: column;
  }

  h3 {
  	margin-bottom: 10px;
  	text-transform: uppercase;
  	font-size: 20px;
  }

  p {
  	font-size: 16px;
  } 


  .service-image {
    display: flex;
    min-width: 400px;
  }

  .gatsby-image-wrapper { 
    width: 100%;

    img {
  	 transition: 600ms ease transform !important;

     &:hover {
        transform: scale(1.1);
      }
    }
  }
  

  .services-content {
    padding: 30px;
    width: 60%;

    @media(max-width: 768px) {
      padding: 30px;
      width: 100%; 
    }

    .btn {
      color: #ffffff;
      text-decoration: none;
      background: ${config.primaryColor};
      cursor: pointer;
      display: inline-block;
      text-transform: uppercase;
      letter-spacing: 1px;
      outline: none;
      position: relative;
      font-size: 14px;
      padding: 10px 20px;
      border: 4px solid ${config.primaryColor};

      &:hover {
        background: ${config.secondaryColor};
        color: ${config.primaryColor};
        border: 4px solid ${config.secondaryColor};
      }
    }
  }
` 

const Container = styled.div`
`

const ServicesContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;

`

export default ServicesHoriz